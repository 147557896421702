<template>
  <power-bi
    code="eyJrIjoiOGNmNTBhMzYtMjU4NC00YTA0LWFkMGUtOWE3NTY2ZjNlMzA0IiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9"
  />
</template>

<script>
import PowerBi from '../components/PowerBi.vue';

export default {
    components: {
        PowerBi,
    },
};
</script>
